import { type CamelCasedPropertiesDeep, type Jsonify } from 'type-fest';
import { z } from 'zod';

import { Order } from '@data/models/Order';
import { addressDTOSchema } from '@data/api/endpoints/shared/addressDTO';
import { contactDTOSchema } from '@data/api/endpoints/shared/contactDTO';
import { journeyDTOSchema } from '@data/api/endpoints/shared/journeyDTO';
import { type NullToUndefined } from '@data/transformers/nullToUndefined';
import { transformDTO } from '@data/transformers/responses';

import { v3MyToshiBaseUrl } from '../../baseUrl';

export const getOrderInfoEndpoint = `${v3MyToshiBaseUrl}/orders`;

export const orderItemDTOSchema = z
  .object({
    id: z.number(),
    image_url: z.string().nullish(),
    name: z.string().nullish(),
    quantity: z.number(),
    size: z.string().nullish(),
  })
  .transform(transformDTO);

export type OrderItemDTO = z.input<typeof orderItemDTOSchema>;
export type OrderItem = z.output<typeof orderItemDTOSchema>;

export const deliveryInfoDTOSchema = z
  .object({
    arrival_eta: z.string().nullish(),
    booking_date: z.string(),
    booking_timeslot_end: z.string(),
    booking_timeslot_start: z.string(),
    delivery_address: z.string(),
  })
  .transform(transformDTO);

export type DeliveryInfoDTO = z.input<typeof deliveryInfoDTOSchema>;
export type DeliveryInfo = z.output<typeof deliveryInfoDTOSchema>;

export const allToshiServicesDTOSchema = z.object({
  dropoff: z.object({
    enabled: z.boolean(),
  }),
  wait_and_try: z.object({
    enabled: z.boolean(),
  }),
  up_down_size: z.object({
    enabled: z.boolean(),
  }),
  perfect_fit: z.object({
    enabled: z.boolean(),
  }),
  alteration: z.object({
    enabled: z.boolean(),
  }),
  inspire_me: z.object({
    enabled: z.boolean(),
    text: z.string().nullish(),
  }),
});

export type AllToshiServicesDTO = z.infer<typeof allToshiServicesDTOSchema>;
export type AllToshiServices = NullToUndefined<CamelCasedPropertiesDeep<z.infer<typeof allToshiServicesDTOSchema>>>;

export const inStoreServicesDTOSchema = z
  .object({
    inspire_me: z.boolean(),
    wait_and_try: z.boolean(),
  })
  .transform(transformDTO);

export type InStoreServicesDTO = z.input<typeof inStoreServicesDTOSchema>;
export type InStoreServices = z.output<typeof inStoreServicesDTOSchema>;

export enum ServiceLevel {
  toshiPlus = 'toshi_plus',
  toshiStandard = 'toshi_standard',
}

export enum LocationCode {
  LDN = 'LDN',
  NYC = 'NYC',
  LA = 'LA',
}

export enum OrderSource {
  online = 'Online',
  inStore = 'In-store',
  toshiOps = 'TOSHI Ops',
  externalCarrier = 'External Carrier',
}

export const addOnPriceOptionDTOSchema = z.object({
  product_option_name: z.string(),
  price_in_cents: z.number(),
  id: z.number(),
});
export type AddOnPriceOptionDTO = z.infer<typeof addOnPriceOptionDTOSchema>;
export type AddOnPriceOption = CamelCasedPropertiesDeep<AddOnPriceOptionDTO>;

export const chargeableAddOnsDTOSchema = z.object({
  id: z.number(),
  chargeable_product_id: z.number(),
  product_name: z.string(),
  add_on_price_options: z.array(addOnPriceOptionDTOSchema),
  product_type: z.literal('delivery_slot'),
});
export type ChargeableAddOnsDTO = z.infer<typeof chargeableAddOnsDTOSchema>;
export type ChargeableAddOns = CamelCasedPropertiesDeep<ChargeableAddOnsDTO>;

const _orderDTOSchema = z
  .object({
    billable_delivery_count: z.number(),
    billable_return_count: z.number(),
    brand_logo_url: z.string().url().nullish(),
    brand_name: z.string(),
    cancelled: z.boolean(),
    chargeable_add_ons: z.array(chargeableAddOnsDTOSchema),
    co_branding_enabled: z.boolean(),
    customer: contactDTOSchema,
    delayed_at_brand: z.boolean(),
    delivery_info: deliveryInfoDTOSchema.nullish(),
    dhl: z.boolean(),
    gift_message: z.string().nullish(),
    gift_receiver: contactDTOSchema.nullish(),
    gift_sender: contactDTOSchema.nullish(),
    id: z.string(),
    // journeys: z.array(journeyDTOSchema).nullish(),
    journeys: z.array(journeyDTOSchema).default([]),
    location_code: z.nativeEnum(LocationCode),
    multi_party_booking: z.boolean(),
    on_behalf_of_brand_name: z.string().nullish(),
    order_interface: z.string(),
    order_items: z.array(orderItemDTOSchema),
    order_number: z.string(),
    reschedule_enabled: z.boolean(),
    service_level: z.nativeEnum(ServiceLevel),
    services: allToshiServicesDTOSchema,
    source: z.nativeEnum(OrderSource),
    shipping_from: z.string().nullish(),
    store_id: z.number(),
    store_services: inStoreServicesDTOSchema,
    unscheduled_order_address: addressDTOSchema.nullish(),
    warehousing_delivery_attempt_count: z.number().nullish(),
  })
  .transform(transformDTO);

export const orderDTOSchema = _orderDTOSchema.transform((data) => new Order(data));

export type OrderDTO = Jsonify<z.input<typeof _orderDTOSchema>>;
export type IOrder = NullToUndefined<z.output<typeof _orderDTOSchema>>;
